import * as React from "react"

import CoverImg from "../images/kenrick-mills-MF9Wy1NA55I-unsplash.jpg"

const Cover = ({ type }) => (
  <section
    style={{
      backgroundImage: `url(${CoverImg})`,
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
      backgroundPositionY: `${ type === "blog" ? "80%" : "bottom"}`,
      backgroundPositionX: "center",
      // marginBottom: `1.45rem`,
      height: `${ type === "blog" ? "480px" : "calc(100vw * 9 /16)"}`,
      maxHeight: "1000px",
    }}
  >
    <div
      style={{
        display: "flex",
        height: "100%",
        margin: `0 auto`,
        maxWidth: 960,
        padding: `1.45rem 1.0875rem`,
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <h1
        style={{
          color: "#fff",
          textShadow: "1px 1px 0px #00000077, 7px 4px 0px #00000077",
        }}
      >
        Les cieux sont ouverts !
      </h1>
    </div>
  </section>
)

export default Cover
