import * as React from "react"

// import LayoutBlog from "../../components/layout-blog"
import Layout from "../../components/layout"
import Seo from "../../components/seo"
import Cover from "../../components/cover"
// import Newsletter from "../../components/newsletter"
import Social from "../../components/social"
import Posts from "../../components/posts"
import CoverBlog from "../../components/cover-blog"
import LayoutBlog from "../../components/layout-blog"

const IndexPage = () => (
  <Layout blog>
    <Seo title="Blog" />
    <CoverBlog postTitle={"Blog"} />
  </Layout>
)

export default IndexPage
